import React from 'react';
import PropTypes from 'prop-types';

import { ErrorManager } from './bridges';
import { defaultConfig } from './ErrorHandler.config';

export class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error) {
    const {
      props: { layer, onError, section },
    } = this;

    if (onError) onError(error);
    ErrorManager.captureException({ error, layer, section });
  }

  render() {
    const {
      props: { children },
    } = this;

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  layer: PropTypes.string,
  section: PropTypes.string,
  onError: PropTypes.func,
};

ErrorHandler.defaultProps = {
  layer: defaultConfig.layer,
  section: defaultConfig.section,
};
