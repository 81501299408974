/* eslint-disable @typescript-eslint/no-explicit-any */

const parseError = (value: any): string | undefined => {
  if (typeof value === 'string') return value;
  return value?.description || value?.status?.name || value?.message;
};

const parseMetadata = (metadata?: Record<string, string>): string => {
  return metadata ? `, metadata=${JSON.stringify(metadata)}` : '';
};

class MendelError extends Error {
  constructor(error: any, metadata?: Record<string, string>) {
    const message = parseError(error) || 'Unknown Error';
    super(message + parseMetadata(metadata));
    this.name = 'MendelError';
  }
}

export { MendelError };
