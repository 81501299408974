import PropTypes from 'prop-types';

import { ErrorManager } from './bridges';
import { defaultConfig } from './ErrorHandler.config';

const ErrorHandlerProvider = ({ dsn, children, release, environment, ignoredErrors, beforeSend }) => {
  ErrorManager.init({ dsn, release, environment, ignoredErrors, beforeSend });
  return children;
};

ErrorHandlerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dsn: PropTypes.string,
  release: PropTypes.string,
  environment: PropTypes.string,
  ignoredErrors: PropTypes.arrayOf(PropTypes.string),
  beforeSend: PropTypes.func,
};

ErrorHandlerProvider.defaultProps = {
  dsn: defaultConfig.dsn,
  environment: defaultConfig.environment,
  layer: defaultConfig.layer,
  section: defaultConfig.section,
  ignoredErrors: defaultConfig.ignoredErrors,
  beforeSend: defaultConfig.beforeSend,
};

export { ErrorHandlerProvider };
