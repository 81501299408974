import { MendelError } from '../types/MendelError';

interface RequestArgs {
  url: string;
  method: string;
  body?: Record<string, unknown>;
}

const parseResponse = async (response: Response): Promise<unknown> => {
  try {
    const text = await response.text();
    return text ? JSON.parse(text) : undefined;
  } catch {
    return undefined;
  }
};

const request = async <T>({ url, method, body }: RequestArgs) => {
  try {
    const response = await fetch(url, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      throw await parseResponse(response);
    }

    return (await parseResponse(response)) as T;
  } catch (e) {
    throw new MendelError(e);
  }
};

const get = <T>(url: string): Promise<T> => {
  return request<T>({ url, method: 'GET' });
};

const post = <T>(url: string, body: RequestArgs['body']): Promise<T> => {
  return request<T>({ url, method: 'POST', body });
};

const put = <T>(url: string, body: RequestArgs['body']): Promise<T> => {
  return request<T>({ url, method: 'PUT', body });
};

export const http = { get, post, put };
