type Resolve = <T>(value?: T | PromiseLike<T> | undefined) => void;
type Reject = (reason?: unknown) => void;

interface QueueEntry {
  value: Promise<unknown>;
  isLoading: boolean;
  resolve: Resolve;
  reject: Reject;
}

export interface Queue {
  add: (id: string) => void;
  get: (id: string) => QueueEntry;
  remove: (id: string) => void;
}

const createPromiseEntry = (): [Promise<unknown>, Resolve, Reject] => {
  let resolveFn: unknown;
  let rejectFn: unknown;
  return [
    new Promise((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;
    }),
    resolveFn as Resolve,
    rejectFn as Reject,
  ];
};

export const createPromiseQueue = (): Queue => {
  const queue: Record<string, { value: Promise<unknown>; resolve: Resolve; reject: Reject }> = {};

  const add = (id: string): void => {
    const [value, resolve, reject] = createPromiseEntry();
    queue[id] = { value, resolve, reject };
  };

  const remove = (id: string): void => {
    delete queue[id];
  };

  const get = (id: string): QueueEntry => {
    return {
      ...queue[id],
      isLoading: Boolean(queue[id]),
      resolve: (value): void => {
        queue[id].resolve(value);
        remove(id);
      },
      reject: (reason): void => {
        queue[id].reject(reason);
        remove(id);
      },
    };
  };

  return { add, remove, get };
};

export const executePromiseFromQueue = async <T>(queue: Queue, id: string, fn: () => Promise<T>): Promise<T> => {
  try {
    if (queue.get(id).isLoading) {
      return queue.get(id).value as T;
    }

    queue.add(id);
    const result = await fn();
    queue.get(id).resolve(result);

    return result;
  } catch (e) {
    if (queue.get(id).isLoading) {
      queue.get(id).reject(e);
    }
    throw e;
  }
};
