import { Store } from './utils/store';
import { createTempId } from './utils/uuid';
import { http } from './utils/mendel-http';
import { AssignedVariant, ExperimentData } from './types/Experiment';
import { createPromiseQueue, executePromiseFromQueue } from './utils/queue';

const SESSION_STORAGE_ID = 'LKEXP';
const COOKIE_STORAGE_ID = 'LKEXP';
const MAX_AGE_STORAGE = Infinity;
const COOKIE_PATH = '/';
const TEMP_PREFIX = 'temp_';

const QUEUE_ID = {
  DEANONYMIZE: 'deanonymize',
  ACTIVE_EXPERIMENTS: 'activeExperiments',
} as const;

export interface IMendel {
  deanonymize(args: { prefix?: string; userId?: string }): Promise<void>;
  getActiveExperiments(force?: boolean): Promise<ExperimentData[]>;
  getAssignedVariantByExperimentName(experimentName: string): AssignedVariant | undefined;
  getUserId(prefix?: string): string;
  getExperimentForUser(
    experimentName: string,
    subjectId: string,
    scope?: string,
    forcedVariationName?: string,
  ): Promise<AssignedVariant | undefined>;
  setApiHost(apiHost: string): void;
  setPrefix(prefix: string): void;
  setUserId(userId: string): string;
  getAllExperimentsForUser(prefix?: string, subjectId?: string, scope?: string): Promise<AssignedVariant[]>;
  deanonymizeProcess(): Promise<void>;
}

const saveUserInCookie = (userId: string): string => {
  Store.setItem(COOKIE_STORAGE_ID, userId, MAX_AGE_STORAGE, COOKIE_PATH);
  return userId;
};

export const saveAllExperiments = (data: AssignedVariant[] = []): AssignedVariant[] => {
  window.sessionStorage.setItem(SESSION_STORAGE_ID, JSON.stringify(data));
  return data;
};

export const saveExperiment = (data: AssignedVariant): AssignedVariant => {
  const previousExperiments = getAllCachedExperiments();
  const newExperiments = [...previousExperiments.filter((exp) => exp.experiment !== data.experiment), data];
  window.sessionStorage.setItem(SESSION_STORAGE_ID, JSON.stringify(newExperiments));
  return data;
};

export const clearCachedExperiments = (): void => {
  window.sessionStorage.removeItem(SESSION_STORAGE_ID);
};

export const getAllCachedExperiments = (): AssignedVariant[] => {
  const cached = window.sessionStorage.getItem(SESSION_STORAGE_ID);
  return cached ? JSON.parse(cached) : [];
};

export const getCachedExperiment = (experimentId: string): AssignedVariant | undefined => {
  return getAllCachedExperiments().find((exp) => exp.experiment === experimentId);
};

const getMendelUserId = (userId: string = '', prefix = ''): string => {
  if (userId) {
    return !userId.startsWith(prefix) ? `${prefix}${userId}` : userId;
  }
  return '';
};

class Mendel implements IMendel {
  config = { apiHost: '', prefix: '' };
  activeExperiments: ExperimentData[] | undefined;
  mendelQueue = createPromiseQueue();

  private executePromiseFromQueue = <T>(id: string, fn: () => Promise<T>) => {
    return executePromiseFromQueue(this.mendelQueue, id, fn);
  };

  async deanonymizeProcess() {
    const promise = this.mendelQueue.get(QUEUE_ID.DEANONYMIZE);
    return promise.isLoading ? (promise.value as Promise<void>) : Promise.resolve();
  }

  async deanonymize({ prefix = this.config.prefix, userId = '' }) {
    return this.executePromiseFromQueue<void>(QUEUE_ID.DEANONYMIZE, async () => {
      const tempId = Store.getItem(COOKIE_STORAGE_ID);
      const mendelUserId = `${prefix}${userId}`;
      if (tempId === mendelUserId) return undefined;

      await http.put<void>(`${this.config.apiHost}/variants`, { tempId, userId: mendelUserId });

      saveUserInCookie(mendelUserId);
      clearCachedExperiments();
    });
  }

  async getActiveExperiments(force?: boolean) {
    if (this.activeExperiments && !force) return this.activeExperiments;
    return this.executePromiseFromQueue<ExperimentData[]>(QUEUE_ID.ACTIVE_EXPERIMENTS, async () => {
      const url = `${this.config.apiHost}/experiments/active`;
      this.activeExperiments = (await http.get<ExperimentData[]>(url)) || [];
      return this.activeExperiments;
    });
  }

  getAssignedVariantByExperimentName(experimentName: string) {
    const experiments = getAllCachedExperiments();
    return experiments.find((exp) => exp.experiment === experimentName);
  }

  getUserId(prefix = this.config.prefix) {
    const userId = Store.getItem(COOKIE_STORAGE_ID);
    return !userId ? saveUserInCookie(`${prefix}${TEMP_PREFIX}${createTempId()}`) : userId;
  }

  async getExperimentForUser(experimentName = '', subjectId = '', scope = '', forcedVariationName = '') {
    // REVIEW using queue gives 'Uncaught (in promise)' error when throwing inside the async fn even if we catch the error in the upper layer
    return this.executePromiseFromQueue<AssignedVariant | undefined>(experimentName, async () => {
      const experimentCached = getCachedExperiment(experimentName);
      if (experimentCached) return experimentCached;

      const activeExperiments = await this.getActiveExperiments();
      const experiment = activeExperiments.find((exp) => exp.name === experimentName);
      if (!experiment) return undefined;

      const experimentIdPart = !forcedVariationName ? `/${experiment.id}` : '';
      const forcedVariationPart = forcedVariationName ? `/${forcedVariationName}` : '';
      const scopePart = scope ? `?${scope}` : '';
      const url = `${this.config.apiHost}${experimentIdPart}/variants/${subjectId}${forcedVariationPart}${scopePart}`;
      const result = await http.get<AssignedVariant | AssignedVariant[]>(url).catch(() => undefined);
      const variant = Array.isArray(result) ? result[0] : result;
      return variant ? saveExperiment(variant) : undefined;
    });
  }

  setApiHost(apiHost: string) {
    this.config.apiHost = apiHost;
  }

  setPrefix(prefix: string) {
    this.config.prefix = prefix;
  }

  setUserId(userId: string) {
    return saveUserInCookie(`${this.config.prefix}${userId}`);
  }

  async getAllExperimentsForUser(prefix = this.config.prefix, subjectId = '', scope = '') {
    const cached = getAllCachedExperiments();
    if (cached.length) return cached;

    const userId = getMendelUserId(subjectId, prefix) || this.getUserId(prefix);
    const scopePart = scope ? `?${scope}` : '';
    const url = `${this.config.apiHost}/variants/${userId}${scopePart}`;

    const variants = await http.get<AssignedVariant[]>(url);
    return saveAllExperiments(variants);
  }
}

export default Mendel;
