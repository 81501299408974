import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

export const ErrorManager = {
  init: ({
    dsn,
    release = undefined,
    environment = 'DEVELOPMENT',
    ignoredErrors = [],
    beforeSend = event => event,
  }) => {
    if (!dsn) return;

    const isProduction = environment === 'PRODUCTION';

    Sentry.init({
      environment,
      dsn,
      release,
      debug: !isProduction,
      integrations: isProduction ? [new BrowserTracing()] : [new Sentry.Integrations.Breadcrumbs({ console: false })],
      tracesSampleRate: 0.5,
      whitelistUrls: [/.*lookiero.*/],
      ignoreErrors: [
        // This configuration is obtained from
        // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        ...ignoredErrors,
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      beforeSend,
    });
  },

  captureException: ({ error, layer, section }) => {
    Sentry.configureScope(scope => {
      scope.setTag('section', section);
      scope.setTag('layer', layer);
      Sentry.captureException(error);
    });
  },

  setUser: user => Sentry.setUser({ user: user }),
};
